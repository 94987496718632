/* ==========================================================================
   #TABLES
   ========================================================================== */

/**
 * 1. Ensure tables fill up as much space as possible.
 */

table:not(.c-events-calendar__table) {
  width: 100% !important; /* [1] */

  td, th {
    padding: rem_calc(5 10);
  }

  td {
    font-size: rem_calc(16);
  }

  p {
    margin: 0;
  }
}

.c-x5__table {
  display: block;
  width: 100% !important; /* [1] */
  height: auto !important;
  margin-top: rem_calc(20);
  margin-bottom: rem_calc(40);
  border: none;
  background-color: $color-white;
  border-collapse: separate !important;
  border-spacing: 0;

  &--shadow {
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
  }

  &--bordered {
    border: solid rem_calc(1);
  }

  &--border-white {
    border: none;
  }

  &--border-grey,
  &--header-bordered#{&}--border-grey th,
  &-rows--bordered#{&}--border-grey td,
  &-cols--bordered#{&}--border-grey th,
  &-cols--bordered#{&}--border-grey td,
  &-rows--striped-bordered#{&}--border-grey td,
  &-rows--striped-bordered-reverse#{&}--border-grey td,
  &-cols--striped-bordered#{&}--border-grey th,
  &-cols--striped-bordered#{&}--border-grey td,
  &-cols--striped-bordered-reverse#{&}--border-grey th,
  &-cols--striped-bordered-reverse#{&}--border-grey td {
    border-color: $color-grey;
  }

  &--border-black,
  &--header-bordered#{&}--border-black th,
  &-rows--bordered#{&}--border-black td,
  &-cols--bordered#{&}--border-black th,
  &-cols--bordered#{&}--border-black td,
  &-rows--striped-bordered#{&}--border-black td,
  &-rows--striped-bordered-reverse#{&}--border-black td,
  &-cols--striped-bordered#{&}--border-black th,
  &-cols--striped-bordered#{&}--border-black td,
  &-cols--striped-bordered-reverse#{&}--border-black th,
  &-cols--striped-bordered-reverse#{&}--border-black td {
    border-color: $color-black;
  }

  &.table-sortable { /* stylelint-disable-line selector-no-qualifying-type */

    th {
      cursor: pointer;
    }

    .tablesorter-header-inner {
      p {
        display: inline-block;
      }
    }

    .tablesorter-icon { /* stylelint-disable-line selector-no-qualifying-type */
      position: relative;
      top: rem_calc(3);
      margin-left: rem_calc(5);
      display: inline-block;
      font-size: 12px;
      width: 11px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
    height: auto !important;
    width: auto !important;
  }

  thead {
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: -9;
    background-color: $color-black;
    color: $color-white;
  }

  &--header-grey thead {
    background-color: $color-grey-light;
  }

  &--header-white thead {
    background-color: $color-white;
    color: $color-black;
  }

  th, td {
    padding: rem_calc(10);
    min-width: rem_calc(80);
    text-align: left;
    vertical-align: top;
    border-color: inherit;

    @include bp-large('max') {
      padding: rem_calc(5);
    }
  }

  th {
    padding: rem_calc(20 10);
    font-weight: bold;
  }

  &--header-bordered th {
    border-bottom: solid rem_calc(1);
  }

  td {
    padding: rem_calc(20 10);
    font-size: rem_calc(16);

    &:before {
      display: table;
      float: left;
      width: 100px;
      min-height: 1px;
      content: attr(data-col-label);
      font-weight: 600;
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }

    .c-td__inner {
      overflow: hidden;
      line-height: 1.2em;
    }
  }

  &-rows--bordered td,
  &-rows--striped-bordered td,
  &-rows--striped-bordered-reverse td {
    border-bottom: solid rem_calc(1);
  }

  &-rows--bordered tr:last-child() td,
  &-rows--striped-bordered tr:last-child() td,
  &-rows--striped-bordered-reverse tr:last-child() td {
    @include bp-medium {
      border-bottom: none;
    }
  }

  @include bp-medium('max') {
    &-rows--bordered tr:last-child() td:last-child(),
    &-rows--striped-bordered tr:last-child() td:last-child(),
    &-rows--striped-bordered-reverse tr:last-child() td:last-child() {
      border-bottom: none;
    }
  }

  &-cols--bordered th,
  &-cols--bordered td,
  &-cols--striped-bordered th,
  &-cols--striped-bordered td,
  &-cols--striped-bordered-reverse th,
  &-cols--striped-bordered-reverse td {
    border-right: solid rem_calc(1);
  }

  &-cols--bordered th:last-child(),
  &-cols--bordered td:last-child(),
  &-cols--striped-bordered th:last-child(),
  &-cols--striped-bordered td:last-child(),
  &-cols--striped-bordered-reverse th:last-child(),
  &-cols--striped-bordered-reverse td:last-child() {
    border-right: none;
  }

  &-rows--striped tbody tr:nth-child(odd) td,
  &-rows--striped-bordered tbody tr:nth-child(odd) td,
  &-rows--striped-reverse tbody tr:nth-child(even) td,
  &-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
    background-color: $color-grey-lighter;
  }

  &-cols--striped th:nth-child(odd),
  &-cols--striped td:nth-child(odd),
  &-cols--striped-reverse th:nth-child(even),
  &-cols--striped-reverse td:nth-child(even),
  &-cols--striped-bordered th:nth-child(odd),
  &-cols--striped-bordered td:nth-child(odd),
  &-cols--striped-bordered-reverse th:nth-child(even),
  &-cols--striped-bordered-reverse td:nth-child(even) {
    background-color: $color-grey-lighter;
  }

  @for $i from 1 through 10 {
    $colW: $i * 5;
    @include bp-medium {
      &--first-col-#{$colW} th:first-child(),
      &--first-col-#{$colW} td:first-child() {
        width: $colW * 1% !important;
      }
    }
  }

  &--row-small td,
  &--header-small th {
    padding: rem_calc(10 10);
  }

  &--row-medium td,
  &--header-medium th {
    padding: rem_calc(15 10);
  }

  &--row-large td,
  &--header-large th {
    padding: rem_calc(25 10);
  }

  &--border-grey td,
  &--border-grey th {
    border-color: $color-grey;
  }

  &--center {
    td {
      @include bp-medium {
        text-align: center;
      }
    }
  }

  &--center-header {
    th {
      @include bp-medium {
        text-align: center;
      }
    }
  }

  &--options-col {
    td:first-child {
      font-weight: bold;
      text-align: left;
    }
  }

  &.table--no-header td {
    &:before {
      content: '';
      width: 0;
    }
  }

  @include bp-medium {
    display: table;

    thead {
      position: relative;
      display: table-header-group;
      top: auto;
      left: auto;
      z-index: 1;
    }

    tbody {
      display: table-row-group;
    }

    tr {
      display: table-row;
      border-bottom: none;
    }

    th,
    td {
      display: table-cell;

      &:before { /* stylelint-disable-line max-nesting-depth */
        content: '';
        display: none;
      }
    }

    &--striped tbody tr:nth-child(even),
    &--striped-reverse tbody tr:nth-child(odd),
    &--striped-cols td:nth-child(even),
    &--striped-cols#{&}--header-white th:nth-child(even),
    &--striped-cols-reverse td:nth-child(odd),
    &--striped-cols-reverse#{&}--header-white th:nth-child(odd) {
      background-color: $color-grey-lighter;
    }

    .c-td__inner {
      overflow: auto;
    }
  }
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table),
.c-x5__table--editor {
  border: solid 1px $color-black;
  border-right: none;
  border-bottom: none;

  td, th {
    border-bottom: 1px solid $color-black;
    border-right: 1px solid $color-black;
  }
}
